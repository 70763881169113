import React from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

import Layout from '../components/layout'
import SEO from '../components/seo'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Grid, Container, Typography } from '@material-ui/core/'
import { Card, FormControl, Select, MenuItem } from '@material-ui/core/'
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: "#eee",
    paddingTop:'0px',
    marginTop:'0px',
  },
  section: {
    padding: theme.spacing(3,3,3,3),
  },
  sectionLeft: {
    padding: theme.spacing(0,1,0,0),
  },
  sectionRight: {
    padding: theme.spacing(1,0,1,1),
    '&:first-child': {
      padding: theme.spacing(0,0,0,1),
    },
  },
  filterPanel1: {
    height: '900px',
  },
  filterPanel2: {
    height: '200px'
  },
  selectTop: {
    width: '232px'
  },
  selectItem: {
    borderWidth:0
  },
  focused: {
    borderWidth:0
  },
  contentPanel: {
    padding: theme.spacing(1,1,1,1),
  },
  resultList: {
    
  },
  textSection:{
    padding: theme.spacing(0,0,2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0,0,3),
    }
  },
}));

function Search(props) {
  const classes = useStyles();
  const { width } = props;
  const [available, setAvailable] = React.useState('');
  const [type, setType] = React.useState('');
  const [sort, setSort] = React.useState('');

  const selectTopStyles = {
    root: classes.selectItem,
    focused: classes.focused, 
  };

  const handleAvailable = (event) => {
    setAvailable(event.target.value);
  };

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleSort = (event) => {
    setSort(event.target.value);
  };

  return(
    <Layout>
      <SEO title="Search Result" />
      <Grid container component="main" className={classes.root} location={props.location.pathname}>

        {/* New Section Here */}
        <Container maxWidth="lg" className={classes.section}>
          <Grid container xs={12} direction="row" justify="center" alignItems="flex-start">
            <Grid container xs={3}>
              <Grid item xs={12} className={classes.sectionLeft}>
                <Card className={classes.filterPanel1}>
                  <Grid container>
                    <Grid item className={classes.contentPanel}>
                      <Typography variant="body1" style={{ fontWeight:'bold'}}>
                        Price Range
                      </Typography>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item className={classes.contentPanel}>
                      <Typography variant="body1" style={{ fontWeight:'bold'}}>
                        Tour Agency
                      </Typography>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item className={classes.contentPanel}>
                      <Typography variant="body1" style={{ fontWeight:'bold'}}>
                        Rate
                      </Typography>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item className={classes.contentPanel}>
                      <Typography variant="body1" style={{ fontWeight:'bold'}}>
                        Location
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid container xs={9} direction="column">
              <Grid item xs={12} className={classes.sectionRight}>
                <Card>
                  <Grid container className={classes.filterPanel2} alignItems="flex-start">
                    <Grid item xs={12} className={classes.contentPanel}>
                      <Typography variant="h6" style={{ fontWeight:'bold'}}>
                        Top Things to Do in Japan
                      </Typography>
                      <Typography variant="body1" style={{ fontWeight:'bold'}}>
                        134 Trips found
                      </Typography>
                    </Grid>
                    <Grid container xs={12} className={classes.contentPanel}>
                      <Grid item>
                        <Typography variant="body2">
                          Availability
                        </Typography>
                        <FormControl variant='outlined' size='small'>
                          <Card>
                            <Select
                              className={classes.selectTop}
                              value={available}
                              onChange={handleAvailable}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value=''>Please Select</MenuItem>
                              <MenuItem value='available 1'>Available 1</MenuItem>
                              <MenuItem value='available 2'>Available 2</MenuItem>
                            </Select>
                          </Card>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          Type of Package
                        </Typography>
                        <FormControl variant='outlined' size='small' classes={selectTopStyles} style={{borderWidth:0}}>
                            <Select
                              className={classes.selectTop}
                              value={type}
                              onChange={handleType}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value=''>Please Select</MenuItem>
                              <MenuItem value='type 1'>Type 1</MenuItem>
                              <MenuItem value='type 2'>Type 2</MenuItem>
                            </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          Sort By
                        </Typography>
                        <FormControl variant='outlined' size='small'>
                          <Card>
                            <Select
                              className={classes.selectTop}
                              value={sort}
                              onChange={handleSort}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value=''>Please Select</MenuItem>
                              <MenuItem value='sort 1'>My Recommendation</MenuItem>
                              <MenuItem value='sort 2'>Sort 2</MenuItem>
                            </Select>
                          </Card>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} className={classes.sectionRight}>
                <Card className={classes.resultPanel}>
                  <Typography gutterBottom variant="h4" align='center' style={{ fontWeight:'bold'}}>
                    Result
                  </Typography>
                </Card>
              </Grid>
              <Grid container xs={12} justify='flex-end'>
                <Grid item className={classes.sectionRight}>
                  <Card className={classes.resultPanel}>
                    <Typography gutterBottom variant="h4" style={{ fontWeight:'bold'}}>
                      Pagination
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

      </Grid>
    </Layout>
  )
}

Search.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Search);